<template>
  <v-btn ref="button" v-bind="attributes" class="s-button" :class="{ '-withLoadingAnimation': attributes.averageLoadingDuration, '-ai': attributes.ai, '-ai--loading': isLoadingLocal }" :style="buttonStyle">
    <AIButtonIconAnimation v-if="attributes.ai" :animate="isLoadingLocal" />
    <slot v-if="$slots.default && !isLoadingLocal" />
    <template v-if="$slots.append" #append>
      <slot name="append" />
    </template>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent, nextTick } from "vue"
import { VBtn } from "vuetify/components"
import AIButtonIconAnimation from "@/components/AIButtonIconAnimation.vue"
import { ComputedGetter } from "vue"

const KBtnProps = VBtn.props
KBtnProps.primary = {
  type: Boolean,
  required: false,
  default: false,
}
KBtnProps.averageLoadingDuration = {
  type: Number,
  required: false,
  default: null,
}
KBtnProps.ai = {
  type: Boolean,
  required: false,
  default: false,
}
KBtnProps.loading = {
  type: Boolean,
  required: false,
  default: false,
}

type KBtnPropsType = Partial<
  InstanceType<typeof VBtn>["$props"] & {
    primary: boolean
    averageLoadingDuration: null | number
    ai: boolean
    loading: boolean
  }
>

export default defineComponent<
  KBtnPropsType,
  any,
  any,
  any,
  any,
  any,
  {
    buttonWidth: number | null
    isLoadingLocal: boolean
  },
  any,
  {
    attributes: ComputedGetter<Record<string, any>>
    buttonStyle: ComputedGetter<Record<string, any>>
  },
  any,
  any,
  any,
  any,
  any,
  any,
  any,
  any,
  any,
  any,
  any,
  any,
  any,
  {
    button: VBtn
  }
>({
  components: { AIButtonIconAnimation },
  props: KBtnProps,
  data() {
    return {
      buttonWidth: null as number | null,
      isLoadingLocal: false,
    }
  },
  computed: {
    attributes(): Record<string, any> {
      let variant = this.$props.variant
      if (!variant || variant === "elevated") {
        variant = "flat"
      }
      const buttonProps: Record<string, any> = {
        ...(this.$props as Record<string, any>),
        ...this.$attrs,
        variant: variant,
      }
      if (!buttonProps.color && variant === "flat") {
        buttonProps.color = buttonProps.primary ? "primary" : "secondary"
      }
      if (buttonProps.disabled) {
        buttonProps.color = ""
      }
      if (buttonProps.ai && this.isLoadingLocal) {
        buttonProps.loading = false
        buttonProps.disabled = true
      }
      return buttonProps
    },
    buttonStyle(): Record<string, any> {
      const style = {
        "--averageLoadingDuration": this.attributes.averageLoadingDuration + "s",
      }
      if (this.buttonWidth && this.attributes.ai && this.isLoadingLocal) {
        return {
          ...style,
          width: `${this.buttonWidth}px`,
          minWidth: `${this.buttonWidth}px`,
        }
      }
      return style
    },
  },
  watch: {
    loading: {
      async handler(newLoading: boolean): Promise<void> {
        // For AI buttons, store the width of the button locally so that we can show our own icon as loading indicator
        if (!this.attributes.ai) {
          return
        }
        if (newLoading && !this.isLoadingLocal) {
          const element = this.$refs.button?.$el
          this.buttonWidth = element?.offsetWidth ?? null
          await nextTick()
          this.isLoadingLocal = true
        } else if (!newLoading) {
          this.isLoadingLocal = false
          this.buttonWidth = null
        }
      },
      immediate: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.s-button {
  &.-ai:not(.v-btn--disabled),
  &.-ai.-ai--loading {
    color: white;
    border-radius: 4px;
    outline: 2px solid $color-tu2;
    background: linear-gradient(90deg, #118495 0%, #a43d53 100%);
  }
}

.s-button.v-btn--loading.-withLoadingAnimation,
.s-button.-ai--loading.-withLoadingAnimation {
  :deep(.v-btn__underlay) {
    background: rgba(0, 0, 0, 0.2);
    animation: s-loadingIndicator var(--averageLoadingDuration) linear;
  }
}

@keyframes s-loadingIndicator {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  100% {
    transform: scaleX(1);
    transform-origin: left;
  }
}
</style>
