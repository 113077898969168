<template>
  <v-list v-if="accountStore.isLoggedIn" density="compact" nav class="bg-vio">
    <v-list-item prepend-icon="far fa-tachometer-alt" :title="$t('sidenavigation.dashboard')" to="/dashboard" />
    <v-list-group v-if="isVisibleForAdmin('stats_users') || isVisibleForAdmin('stats_courses') || isVisibleForAdmin('stats_quiz_challenge') || isVisibleForAdmin('stats_training') || isVisibleForAdmin('stats_wbt') || isVisibleForAdmin('stats_views') || isVisibleForAdmin('stats_ratings')">
      <template #activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="far fa-chart-line" :title="$t('sidenavigation.statistics')" />
      </template>
      <v-list-item v-if="isVisibleForAdmin('stats_users')" :title="$t('sidenavigation.user')" to="/stats/users" />
      <v-list-item v-if="isVisibleForAdmin('stats_courses')" :title="$t('sidenavigation.courses')" to="/analytics/courses" />
      <v-list-item v-if="isVisibleForAdmin('stats_quiz_challenge')" :title="$t('sidenavigation.quizBattle')" to="/stats/quiz" />
      <v-list-item v-if="isVisibleForAdmin('stats_training')" :title="$t('sidenavigation.powerlearning')" to="/powerlearning/analytics" />
      <v-list-item v-if="isVisibleForAdmin('stats_wbt')" :title="$t('sidenavigation.wbtStatistics')" to="/stats/wbt" />
      <v-list-item v-if="isVisibleForAdmin('stats_views')" :title="$t('sidenavigation.views')" to="/stats/views" />
      <v-list-item v-if="isVisibleForAdmin('stats_ratings')" :title="$t('sidenavigation.reviews')" to="/stats/ratings" />
      <v-list-item v-if="isVisibleForAdmin('stats_user_activity')" :title="$t('sidenavigation.userActivity')" to="/superadmin/user-activity" />
    </v-list-group>
    <v-list-group v-if="isVisibleForAdmin('users') || isVisibleForAdmin('vouchers')">
      <template #activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="far fa-users" :title="$t('sidenavigation.user')" :to="isVisibleForAdmin('users') ? '/users' : undefined" />
      </template>
      <v-list-item v-if="isVisibleForAdmin('users')" :title="$t('sidenavigation.accounts')" to="/users" />
      <v-list-item v-if="isVisibleForAdmin('user_roles')" :title="$t('sidenavigation.roles')" to="/user-roles" />
      <v-list-item v-if="isVisibleForAdmin('vouchers')" :title="$t('sidenavigation.vouchers')" to="/vouchers" />
    </v-list-group>
    <v-list-item v-if="isVisibleForAdmin('tags')" prepend-icon="far fa-tags" :title="$t('sidenavigation.tags')" to="/tags" />
    <v-list-item v-if="isVisibleForAdmin('courses')" prepend-icon="far fa-graduation-cap" :title="$t('sidenavigation.course')" to="/courses" />
    <v-list-group
      v-if="
        isVisibleForAdmin('learningmaterials') ||
        isVisibleForAdmin('questions') ||
        isVisibleForAdmin('suggested_questions') ||
        isVisibleForAdmin('categories') ||
        isVisibleForAdmin('forms') ||
        isVisibleForAdmin('tests') ||
        isVisibleForAdmin('appointments') ||
        isVisibleForAdmin('keywords') ||
        isVisibleForAdmin('news') ||
        isVisibleForAdmin('webinars') ||
        isVisibleForAdmin('advertisements')
      "
    >
      <template #activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="far fa-spell-check" :title="$t('sidenavigation.contents')" />
      </template>
      <v-list-item v-if="isVisibleForAdmin('learningmaterials')" :title="$t('sidenavigation.learningMaterials')" to="/media-library" />
      <v-list-item v-if="isVisibleForAdmin('questions')" :title="$t('sidenavigation.questions')" :to="{ name: 'questions' }" />
      <v-list-item v-if="isVisibleForAdmin('suggested_questions')" :title="$t('sidenavigation.userSubmittedQuestions')" to="/suggested-questions" />
      <v-list-item v-if="isVisibleForAdmin('categories')" :title="$t('sidenavigation.questionCategory')" to="/categories" />
      <v-list-item v-if="isVisibleForAdmin('forms')" :title="$t('sidenavigation.forms')" to="/forms" />
      <v-list-item v-if="isVisibleForAdmin('tests')" :title="$t('sidenavigation.tests')" to="/tests" />
      <v-list-item v-if="isVisibleForAdmin('appointments')" :title="$t('sidenavigation.appointments')" to="/appointments" />
      <v-list-item v-if="isVisibleForAdmin('keywords')" :title="$t('sidenavigation.keywords')" to="/keywords" />
      <v-list-item v-if="isVisibleForAdmin('news')" :title="$t('sidenavigation.news')" to="/news" />
      <v-list-item v-if="isVisibleForAdmin('webinars')" :title="$t('sidenavigation.webinars')" to="/webinars" />
      <v-list-item v-if="isVisibleForAdmin('advertisements')" :title="$t('sidenavigation.advertisements')" to="/advertisements" />
    </v-list-group>
    <v-list-group v-if="isVisibleForAdmin('quiz_teams') || isVisibleForAdmin('competitions')">
      <template #activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="far fa-book" :title="$t('sidenavigation.quizBattle')" />
      </template>
      <v-list-item v-if="isVisibleForAdmin('quiz_teams')" :title="$t('sidenavigation.quizTeams')" to="/quiz-teams" />
      <v-list-item v-if="isVisibleForAdmin('competitions')" :title="$t('sidenavigation.quizCompetitions')" to="/competitions" />
    </v-list-group>
    <v-list-item v-if="isVisibleForAdmin('comments')" prepend-icon="far fa-comment" :title="$t('sidenavigation.comments')" to="/comments" />
    <v-list-item v-if="typeOptions.length" prepend-icon="far fa-language" :title="$t('sidenavigation.translations')" to="/translations" />
    <v-list-group v-if="isVisibleForAdmin('settings') || isVisibleForAdmin('tenants') || isVisibleForAdmin('import') || isVisibleForAdmin('pages') || isVisibleForAdmin('mails') || isVisibleForAdmin('certificates')">
      <template #activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="far fa-cog" :title="$t('sidenavigation.settings')" :to="isVisibleForAdmin('settings') ? '/settings' : undefined" />
      </template>
      <v-list-item v-if="isVisibleForAdmin('settings')" :title="$t('sidenavigation.app')" to="/settings">
        <!-- For intercom to attach to -->
        <template #append><span class="s-list-append">&nbsp;</span></template>
      </v-list-item>
      <v-list-item v-if="isVisibleForAdmin('public_api')" :title="$t('sidenavigation.publicApiTokens')" to="/settings/public-api-tokens">
        <!-- For intercom to attach to -->
        <template #append><span class="s-list-append">&nbsp;</span></template>
      </v-list-item>
      <v-list-item v-if="isVisibleForAdmin('tenants')" :title="$t('sidenavigation.tenants')" to="/tenants">
        <!-- For intercom to attach to -->
        <template #append><span class="s-list-append">&nbsp;</span></template>
      </v-list-item>
      <v-list-item v-if="isVisibleForAdmin('import')" :title="$t('sidenavigation.import')" to="/import">
        <!-- For intercom to attach to -->
        <template #append><span class="s-list-append">&nbsp;</span></template>
      </v-list-item>
      <v-list-item v-if="isVisibleForAdmin('pages')" :title="$t('sidenavigation.pages')" to="/pages" />
      <v-list-item v-if="isVisibleForAdmin('mails')" :title="$t('sidenavigation.emailTemplate')" to="/mails" />
      <v-list-item v-if="isVisibleForAdmin('certificates')" :title="$t('sidenavigation.certificateTemplates')" to="/certificate-templates" />
      <v-list-item v-if="accountStore.isSuperAdmin && isStaging" :title="$t('sidenavigation.oldCronJobs')" to="/settings/old/cron-jobs" />
      <v-list-item v-if="accountStore.isSuperAdmin && isStaging" :title="$t('sidenavigation.cronJobs')" to="/settings/cron-jobs" />
    </v-list-group>
    <v-list-item v-if="isVisibleForAdmin('bin')" prepend-icon="far fa-trash" :title="$t('sidenavigation.bin')" to="/bin" />
  </v-list>
</template>

<script setup lang="ts">
import { useAccountStore } from "@/modules/account/store"
import { useAppConfigStore } from "@/store/appConfig"
import { ModuleNameWithVisibilityCheck } from "@/logic/modules"
import { exhaustiveCheck } from "@/logic/tsHelpers"
import { useTranslationsTypes } from "@/modules/translations/composables/translationsTypes"
import { computed } from "vue"

const accountStore = useAccountStore()
const appConfigStore = useAppConfigStore()

type Area = Exclude<
  | ModuleNameWithVisibilityCheck
  | "categories"
  | "import"
  | "mails"
  | "pages"
  | "settings"
  | "stats_ratings"
  | "stats_training"
  | "stats_users"
  | "stats_quiz_challenge"
  | "stats_user_activity"
  | "stats_views"
  | "stats_wbt"
  | "tags"
  | "tag_groups"
  | "quiz_teams"
  | "users"
  | "user_roles"
  | "bin"
  | "stats_courses",
  "quiz"
>

const typeOptions = useTranslationsTypes()

const isVisibleForAdmin = (area: Area): boolean => {
  switch (area) {
    case "advertisements":
      return appConfigStore.isModuleVisible("advertisements") && accountStore.rights["advertisements-edit"]
    case "appointments":
      return appConfigStore.isModuleVisible("appointments") && (accountStore.rights["appointments-edit"] || accountStore.rights["appointments-view"])
    case "categories":
      return appConfigStore.isModuleVisible("questions") && accountStore.rights["categories-edit"]
    case "competitions":
      return appConfigStore.isModuleVisible("competitions") && accountStore.rights["competitions-edit"]
    case "comments":
      return appConfigStore.isModuleVisible("comments") && accountStore.rights["comments-personaldata"]
    case "courses":
      return appConfigStore.isModuleVisible("courses") && (accountStore.rights["courses-edit"] || accountStore.rights["courses-view"])
    case "forms":
      return appConfigStore.isModuleVisible("forms") && accountStore.rights["forms-edit"]
    case "keywords":
      return appConfigStore.isModuleVisible("keywords") && accountStore.rights["keywords-edit"]
    case "import":
      return (!!appConfigStore.settings.import_questions && accountStore.rights["questions-edit"]) || (!!appConfigStore.settings.import_users && accountStore.rights["users-edit"]) || (!!appConfigStore.settings.import_users_delete && accountStore.rights["users-edit"])
    case "learningmaterials":
      return appConfigStore.isModuleVisible("learningmaterials") && accountStore.rights["learningmaterials-edit"]
    case "mails":
      return accountStore.rights["mails-edit"]
    case "news":
      return appConfigStore.isModuleVisible("news") && accountStore.rights["news-edit"]
    case "pages":
      return accountStore.rights["pages-edit"]
    case "public_api":
      return appConfigStore.isModuleVisible("public_api") && accountStore.isMainAdmin
    case "settings":
      return accountStore.rights["settings-edit"]
    case "stats_ratings":
      return accountStore.rights["settings-ratings"]
    case "stats_training":
      return !appConfigStore.settings.hide_stats_training && accountStore.rights["questions-stats"]
    case "stats_quiz_challenge":
      return appConfigStore.isModuleVisible("quiz") && !appConfigStore.settings.hide_stats_quiz_challenge && accountStore.rights["questions-stats"]
    case "stats_user_activity":
      return accountStore.isSuperAdmin
    case "stats_users":
      return !appConfigStore.settings.hide_stats_users && accountStore.rights["users-stats"]
    case "stats_courses":
      return appConfigStore.isModuleVisible("courses") && accountStore.rights["courses-stats"]
    case "stats_views":
      return !appConfigStore.settings.hide_stats_views && accountStore.rights["settings-viewcounts"]
    case "stats_wbt":
      return !appConfigStore.settings.hide_stats_wbt && !!appConfigStore.settings.wbt_enabled && accountStore.rights["learningmaterials-stats"]
    case "tags":
      return accountStore.rights["tags-edit"]
    case "tag_groups":
      return accountStore.rights["tags-edit"] && !appConfigStore.settings.hide_tag_groups
    case "suggested_questions":
      return appConfigStore.isModuleVisible("suggested_questions") && accountStore.rights["suggestedquestions-edit"]
    case "tests":
      return appConfigStore.isModuleVisible("tests") && (accountStore.rights["tests-edit"] || accountStore.rights["tests-view"])
    case "questions":
      return appConfigStore.isModuleVisible("questions") && accountStore.rights["questions-edit"]
    case "quiz_teams":
      return appConfigStore.isModuleVisible("quiz") && accountStore.rights["quizteams-personaldata"]
    case "users":
      return accountStore.rights["users-edit"] || accountStore.rights["users-view"]
    case "user_roles":
      return accountStore.isMainAdmin
    case "vouchers":
      return appConfigStore.isModuleVisible("vouchers") && accountStore.rights["vouchers-edit"]
    case "webinars":
      return appConfigStore.isModuleVisible("webinars")
    case "tenants":
      return appConfigStore.isModuleVisible("tenants") && accountStore.isMainAdmin
    case "bin":
      return accountStore.rights["tags-edit"] || (appConfigStore.isModuleVisible("appointments") && accountStore.rights["appointments-edit"])
    case "certificates":
      return appConfigStore.isModuleVisible("certificates") && accountStore.rights["courses-certificatetemplates"]
    default:
      exhaustiveCheck(area)
  }
}

const isStaging = computed(() => {
  return window.location.hostname === "myadmin.staging.keelearning.de"
})
</script>

<style lang="scss" scoped>
:deep(.v-list-item--nav .v-list-item-title) {
  line-height: 1.5rem; // Due to our font, otherwise the letter "g" gets cut off at the bottom
}
</style>
