export function getDashboardRoutes() {
  return [
    {
      path: "/",
      name: "home",
      redirect: { name: "dashboard" },
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import(/* webpackChunkName: "dashboard-dashboard" */ "./views/DashboardView.vue"),
    },
  ]
}
