<template>
  <div class="s-stars">
    <div class="s-star__container" :style="animationPlayState">
      <!-- Inline SVGs so that currentColor works -->
      <div class="s-star -star-1">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.3866 4.0305C11.9799 4.09564 12.1985 4.87732 11.7926 5.30072L9.41933 7.71089L9.98141 11.1307C9.98141 11.1633 9.98141 11.1958 9.98141 11.2284C9.98141 11.6518 9.66914 11.9775 9.26319 11.9775C9.13829 11.9775 9.01338 11.945 8.9197 11.9124L5.98439 10.2839L3.0803 11.9124C2.54945 12.2055 1.92491 11.717 2.01859 11.1307L2.58067 7.71089L0.20744 5.30072C-0.198508 4.87732 0.0200793 4.09564 0.613387 4.0305L3.8922 3.50939L5.35985 0.415264C5.60967 -0.138421 6.39033 -0.138421 6.64015 0.415264L8.10781 3.50939L11.3866 4.0305Z"
            fill="currentColor"
          />
        </svg>
      </div>
      <div class="s-star -star-2">
        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 3.52174C0 3.6087 0.0869559 3.69565 0.173912 3.73913L2.34783 4.65217L3.26087 6.82609C3.30435 6.91304 3.3913 7 3.52174 7C3.6087 7 3.69565 6.91304 3.73913 6.82609L4.65217 4.65217L6.82609 3.73913C6.91304 3.69565 7 3.6087 7 3.47826C7 3.3913 6.91304 3.30435 6.82609 3.26087L4.65217 2.34783L3.73913 0.173913C3.69565 0.0869565 3.6087 0 3.47826 0C3.3913 0 3.30435 0.0869565 3.26087 0.173913L2.34783 2.34783L0.173912 3.26087C0.0869559 3.30435 0 3.3913 0 3.52174Z"
            fill="currentColor"
          />
        </svg>
      </div>
      <div class="s-star -star-3">
        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 3.52174C0 3.6087 0.0869559 3.69565 0.173912 3.73913L2.34783 4.65217L3.26087 6.82609C3.30435 6.91304 3.3913 7 3.52174 7C3.6087 7 3.69565 6.91304 3.73913 6.82609L4.65217 4.65217L6.82609 3.73913C6.91304 3.69565 7 3.6087 7 3.47826C7 3.3913 6.91304 3.30435 6.82609 3.26087L4.65217 2.34783L3.73913 0.173913C3.69565 0.0869565 3.6087 0 3.47826 0C3.3913 0 3.30435 0.0869565 3.26087 0.173913L2.34783 2.34783L0.173912 3.26087C0.0869559 3.30435 0 3.3913 0 3.52174Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

const props = defineProps({
  animate: {
    type: Boolean,
    default: false,
  },
})

const animationPlayState = computed(() => ({
  "--animation-play-state": props.animate ? "running" : "paused",
}))
</script>

<style lang="scss" scoped>
.s-stars {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: relative;
}

.s-star__container {
  position: relative;
  width: 100%;
  height: 100%;
  --animation-play-state: running;
}

.s-star {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center;
  animation: orbit 3s linear infinite;
  animation-play-state: var(--animation-play-state);

  svg {
    width: 100%;
    height: auto;
    max-width: 10px;
    transform-origin: center;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: var(--animation-play-state);
  }
}

.s-star.-star-1 {
  --star-rotation-angle: 60deg;
  transform: rotate(var(--star-rotation-angle)) translateY(6px) rotate(calc(-1 * var(--star-rotation-angle))) scale(1.2);

  svg {
    max-width: 12px;
    animation-name: pulse-star1;
  }
}

.s-star.-star-2 {
  --star-rotation-angle: 210deg;
  transform: rotate(var(--star-rotation-angle)) translateY(6px) rotate(calc(-1 * var(--star-rotation-angle))) scale(0.9);

  svg {
    max-width: 8px;
    animation-name: pulse-star2;
  }
}

.s-star.-star-3 {
  --star-rotation-angle: 310deg;
  transform: rotate(var(--star-rotation-angle)) translateY(6px) rotate(calc(-1 * var(--star-rotation-angle))) scale(0.6);

  svg {
    max-width: 8px;
    animation-name: pulse-star3;
  }
}

@keyframes orbit {
  0% {
    transform: rotate(var(--star-rotation-angle)) translateY(6px) rotate(calc(-1 * var(--star-rotation-angle)));
  }
  100% {
    transform: rotate(calc(var(--star-rotation-angle) + 360deg)) translateY(6px) rotate(calc(-1 * (var(--star-rotation-angle) + 360deg)));
  }
}

@keyframes pulse-star1 {
  0% {
    transform: scale(1.2);
  }
  41.67% {
    transform: scale(0.8);
  }
  91.67% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1.2);
  }
}

@keyframes pulse-star2 {
  0% {
    transform: scale(0.9);
  }
  41.67% {
    transform: scale(1.5);
  }
  91.67% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.9);
  }
}

@keyframes pulse-star3 {
  0% {
    transform: scale(0.6);
  }
  13.89% {
    transform: scale(1.5);
  }
  63.89% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.6);
  }
}
</style>
